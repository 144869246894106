import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import AppContext from "../../AppContext";
import { getUser } from "../../db/FirebaseMethods";

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      loginSuccessful: false,
    };
  }

  componentDidMount() {}

  handleLogin = async () => {
    let user = await getUser(this.state.email, this.state.pass);
    this.context.setUser(user);
    if (this.context.userIsAnAdmin) this.setState({ loginSuccessful: true });
  };

  render() {
    if (this.state.loginSuccessful) return <Redirect to="/" />;

    return (
      <AppContext.Consumer>
        {(value) => {
          return (
            <form
            autoComplete="on"
            autoSave="on"
            
            onSubmit={(e)=>{
              e.preventDefault();
              this.handleLogin();
            }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <b>YÖNETİCİ GİRİŞİ</b>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "30px",
                  justifyContent: "center",
                }}
              >
                <span style={{ marginRight: "20px" }}>E-Mail</span>
                <TextField
                  variant="standard"
                  name="email"
                  value={this.setState.email}
                  onChange={(event) =>
                    this.setState({
                      email: event.target.value,
                    })
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "30px",
                  justifyContent: "center",
                }}
              >
                <span style={{ marginRight: "20px" }}>Şifre</span>
                <TextField
                name="password"
                  variant="standard"
                  type="password"
                  value={this.setState.pass}
                  onChange={(event) =>
                    this.setState({
                      pass: event.target.value,
                    })
                  }
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                GİRİŞ
              </Button>
            </form>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default Login;
