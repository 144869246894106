import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import trLocale from "date-fns/locale/tr";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Urls from "../../constants/URLConstants";

import AppContext from "../../AppContext";
import { Link } from "react-router-dom";
import { logout } from "../../db/FirebaseMethods";
import EditQuestion from "./EditQuestion";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SortIcon from "@material-ui/icons/Sort";
const useStyles = (theme) => ({
  root: {
    backgroundColor: "#FFCCBC",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    borderBottomLeftRadius: "40px",
    borderBottomRightRadius: "40px",
  },
  links: {
    color: "black",
    textDecoration: "none",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
});

class AdminHeader extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      // selectedDate: new Date(),
      newQuestionModalVisible: false,
    };
  }

  handleChangeDate = (date, event) => {
    this.context.changeDate(date);
  };

  renderTimeSelector = () => {
    const { classes } = this.props;
    const isPullingQuestions = this.context.isPullingQuestions;

    const maxDate = isPullingQuestions
      ? new Date().setDate(new Date().getDate() - 10000000)
      : new Date().setDate(new Date().getDate() + 100);

    // Herhangi bir gün için henüz rastgele soru seçilmediyse, o tarihe tıklayınca soru seçme işlemi başlayacak. Bu işlem biraz uzun olduğu için
    // admin o esnada başka tarihe tıklayıp geri eski tarihi seçerse soru seçme işlemi yeniden tetikleniyor, iki işlem aynı anda devam ederek
    // o güne aynı kategoriden 1'den fazla soru eklenmesine sebep veriyordu...
    // bunu engellemek için arka planda soru seçilirken tarih seçiciyi disable hale getirmek mantıklı... Bunun için maxDate'i kullandım.

    return (
      <div style={{ maxHeight:"55px"}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
          <KeyboardDatePicker
            style={{ width: "150px" }}
            maxDate={maxDate}
            variant="inline"
            margin="normal"
            id="date-picker-dialog"
            format="dd.MM.yyyy"
            value={this.context.selectedDate}
            onChange={this.handleChangeDate}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  renderNewQuestionModal = () => {
    return (
      <EditQuestion
        onClose={() => {
          this.setState({ newQuestionModalVisible: false });
        }}
      />
    );
  };

  render() {
    if (!this.context.userIsAnAdmin) return null;

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <span
          className={classes.links}
          onClick={() => this.setState({ newQuestionModalVisible: true })}
        >
          <AddCircleOutlineIcon />
          Soru Ekle
        </span>
        <Link className={classes.links} to={Urls.admin.sorting}>
          <SortIcon />
          Sıralama
        </Link>
        <Link className={classes.links} to={Urls.admin.archive}>
          Arşiv
        </Link>
        <Link className={classes.links} to={Urls.admin.settings}>
          Ayarlar
        </Link>
        {this.renderTimeSelector()}
        <span className={classes.links} onClick={logout}>
          <ExitToAppIcon />
          Logout
        </span>
        {this.state.newQuestionModalVisible && this.renderNewQuestionModal()}
      </div>
    );
  }
}

export default withStyles(useStyles)(AdminHeader);
