import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/Visibility";
import EditQuestion from "./admin/EditQuestion";
import AnswerModal from "./AnswerModal";
import "../css/Styles.css";
import AppContext from "../AppContext";


const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    background: "#F2F2F2",
    borderRadius: "0.7rem",
    marginBottom: "15px",
  },

  bigImagePaper: {
    borderRadius: "0.7rem",
    marginBottom: "15px",
  },

  subtitle: {
    fontSize: "1rem",
    color: "#434343",
    marginTop: "15px",
    whiteSpace: "pre-wrap",
    lineHeight: "1.5rem",
  },
  label: {
    backgroundColor: "#00A0B4",
  },
  image: {
    // width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    // marginTop: "15px",
    // borderRadius: "0.7rem",
  },

  imageBackground: {
    // boxSizing:"border-box",
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: "0.7rem",
    margin: "20px 0 20px 0",
    padding: "20px",
  },

  bigImage: {
    width: "100%",
    height: "100%",
    borderRadius: "0.7rem",
  },

  bigImageBackground: {
    // boxSizing:"border-box",
    // textAlign :"center",
    // backgroundColor:"white",
    borderRadius: "0.7rem",
    // margin :"20px 0 20px 0",
    // padding:"20px"
  },
});

class QuestionCard extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      editIconVisible: false,
      editModalVisible: false,
      answerModalVisible: false,
      fadeOut: false
    };
  }

  onMouseOver = () => {
    this.setState({ editIconVisible: true, fadeOut: true });
  };

  onMouseOut = () => {
    this.setState({ editIconVisible: false, fadeOut: false });
  };

  onEditClicked = () => {
    this.setState({ editModalVisible: true });
  };

  renderEditModal = () => {
    return (
      <EditQuestion
        dbKey={this.props.item.dbKey}
        onClose={() => {
          this.setState({ editModalVisible: false });
        }}
      />
    );
  };

  renderAnswerModal = () => {
    const item = this.context.answers.find(i => i.dbKey === this.props.item.dbKey);
    return <AnswerModal
      dbKey={this.props.item.dbKey}
      onClose={() => {
        this.setState({ answerModalVisible: false, fadeOut: false });
      }}
      item={item}
    />
  }

  render() {
    const { classes } = this.props;
    const item = this.props.item;

    const title = (
      <div className="questionCardHeaderRow">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="questionCardTitle">{item.title}</span>
          {/* <span className="questionCardCategory">
            {"Kategori : " + item.category}
          </span> */}
        </div>
        <span className="questionCardNumber">No: {item.no || "???"}</span>
      </div>
    );

    const subtitle_1 = item.subtitle1 && (
      <div className={classes.subtitle}>{item.subtitle1}</div>
    );

    const sampleText = item.sampleText && (
      <div className={classes.subtitle}>{item.sampleText}</div>
    );

    const img = item.imageSource && (
      <Grid
        item
        className={
          item.imageCoversTheQuestion
            ? classes.bigImageBackground
            : classes.imageBackground
        }
      >
        <img
          className={
            item.imageCoversTheQuestion ? classes.bigImage : classes.image
          }
          src={item.imageSource}
          alt="question"
        />
      </Grid>
    );

    const sampleImage = item.sampleImageSource && (
      <Grid item className={classes.imageBackground}>
        <img
          className={classes.image}
          src={item.sampleImageSource}
          alt="sampleImage"
        />
      </Grid>
    );

    // const img= null;
    // const sampleImage = null;

    const headerImage = item.headerImageSource && (
      <Grid item className={classes.imageBackground}>
        <img
          className={classes.image}
          src={item.headerImageSource}
          alt={"headerImage"}
        />
      </Grid>
    );

    const externalLink =
      item.imageRedirectionUrl &&
        item.imageRedirectionUrl.substring(0, 4) !== "http"
        ? "http://" + item.imageRedirectionUrl
        : item.imageRedirectionUrl;
    const linkImage = (
      <a target="_blank" rel="noopener noreferrer" href={externalLink}>
        {img}
      </a>
    );

    const editIcon = (
      <div
        onClick={this.onEditClicked}
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "#FFAB91",
          borderRadius: "999px",
          display: this.state.editIconVisible ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "absolute",
          top: "0",
          right: "0",
          zIndex: 3,
        }}
      >
        <EditIcon />
      </div>
    );

    const answerButton = (
      <div
        onClick={() => {
          this.setState({ answerModalVisible: true });
        }}


        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: 2,
          borderRadius: 12,
          paddingBottom:50,
          display:"flex",
        }}
      >

        <div
          style={{
            width: "100%",
            backgroundColor: "#08020080",
            color: "white",
            fontWeight: "bold",
            display: this.state.fadeOut ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 12,
            marginBottom:15,
          }}>

        </div>

      </div>
    );

    const imageCoversTheQuestion =
      item.imageCoversTheQuestion && item.imageCoversTheQuestion === true;

    return (
      <div
        className={
          imageCoversTheQuestion ? classes.bigImagePaper : classes.paper
        }
        // elevation={3}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        {!imageCoversTheQuestion && title}
        {!imageCoversTheQuestion && headerImage}

        {!imageCoversTheQuestion && subtitle_1}
        {item.imageRedirectionUrl ? linkImage : img}
        {!imageCoversTheQuestion && sampleText}
        {!imageCoversTheQuestion && sampleImage}
        {this.props.editible && editIcon}
        {this.state.editModalVisible && this.renderEditModal()}
        {this.state.answerModalVisible && this.renderAnswerModal()}
        {this.props.type === "q" && !item.imageRedirectionUrl && answerButton}
      </div>
    );
  }
}

export default withStyles(useStyles)(QuestionCard);
