import React, { Component } from "react";
import "./App.css";
import GlobalHeader from "./components/GlobalHeader";
import Footer from "./components/Footer";
import { Redirect, Switch, withRouter } from "react-router-dom";
import AdminHeader from "./components/admin/AdminHeader";
import { AppProvider } from "./AppContext";
import RouteGenerator from "./components/RouteGenerator";
import Questions from "./components/Questions";
import Answers from "./components/Answers";
import Login from "./components/admin/Login";
import Sorting from "./components/admin/Sorting";
import Urls from "./constants/URLConstants";
import Settings from "./components/admin/Settings";
import Archive from "./components/admin/Archive";

class App extends Component {
 
  render() {
    const pageContent = (
      <Switch>
        <RouteGenerator path={Urls.questions} component={<Questions />} />
        <RouteGenerator path={Urls.answers} component={<Answers />} />
        <RouteGenerator path={Urls.admin.login} component={<Login />} />

        <RouteGenerator
          path={Urls.admin.sorting}
          component={<Sorting />}
          onlyForAdmin={true}
        />
        <RouteGenerator
          path={Urls.admin.archive}
          component={<Archive />}
          onlyForAdmin={true}
        />

        <RouteGenerator
          path={Urls.admin.settings}
          component={<Settings />}
          onlyForAdmin={true}
        />

        <RouteGenerator path={"/"} component={<Questions />} />

        <Redirect to="/" />
      </Switch>
    );

    return (
      <AppProvider>
        <div className="AppBody">
          <div style={{ flex: 1}}>
            <AdminHeader />
            <GlobalHeader path={this.props.location.pathname} />
            {pageContent}
          </div>
          <Footer />
        </div>
      </AppProvider>
    );
  }
}

export default withRouter(App);
