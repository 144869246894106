// import firebase from 'firebase/app';
import React, { Component } from "react";
import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Switch,
  Button,
  TableHead,
  Radio,
  TextField,
} from "@material-ui/core";

import {
  pullCategories,
  updateCategory,
  getNoFromSequence,
  getSetSequence,
  pullSettings,
  updateSettings,
} from "../../db/FirebaseMethods";
// import 'firebase/storage';

import AppContext from "../../AppContext";
import "../../css/Styles.css";
import { firebaseStorage, firebaseDatabase } from "../../config/firebaseConfig";

const loadingIndicator = (
  <Backdrop style={{ zIndex: 999, color: "#fff" }} open={true}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

class Settings extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      isLoading: false,
      randomCategorySubsetSize: 0,
      randomCategorySubsetSizeOnRemote: 0,
    };
  }

  componentDidMount() {
    this.pullCategoriesFromDb();
    this.pullSettingsTable();
  }

  pullCategoriesFromDb = async () => {
    let categories = await pullCategories();
    categories = categories.sort((c1, c2) => {
      return c1.questionSelectionMethod > c2.questionSelectionMethod;
    });

    this.setState({ categories });
  };

  updateCategoryOnDb = async (c) => {
    this.setState({ isLoading: true });

    updateCategory({
      name: c.name,
      questionSelectionMethod: c.questionSelectionMethod,
    }).then((_) =>
      this.pullCategoriesFromDb().then((_) =>
        this.setState({ isLoading: false })
      )
    );
  };

  pullSettingsTable = () => {
    pullSettings().then((res) =>
      this.setState({
        randomCategorySubsetSize: res.randomCategorySubsetSize,
        randomCategorySubsetSizeOnRemote: res.randomCategorySubsetSize,
      })
    );
  };

  updateSettingsTable = async () => {
    let newSize = this.state.randomCategorySubsetSize;

    const onSuccessFunction = () => {
      this.pullSettingsTable();
      alert("Güncelleme başarılı.");
    };

    const onFailFunction = () => {
      alert("Hata...! İşlem başarısız.");
    };

    if (isNaN(newSize)) alert("HATA: Lütfen bir tam sayı giriniz! ");
    else {
      newSize = parseInt(newSize);
      const res = await updateSettings({
        ["settings/randomCategorySubsetSize"]: newSize,
      }).then(onSuccessFunction, onFailFunction);
    }
  };

  renderCategoryTable = () => {
    const categories = this.state.categories;

    const rows = categories.map((c) => {
      return (
        <TableRow key={c.name}>
          <TableCell style={{ padding: "5px", fontSize: "14pt" }}>
            {c.name}
          </TableCell>
          <TableCell style={{ padding: "5px" }}>
            <Switch
              checked={c.selectRandom}
              onChange={() => {
                c.selectRandom = !c.selectRandom;
                this.setState({ categories });
                this.updateCategoryOnDb({
                  name: c.name,
                  selectRandom: c.selectRandom,
                });
              }}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </TableCell>
        </TableRow>
      );
    });

    return (
      <TableContainer style={{ width: "300px" }}>
        <span style={{ fontWeight: "bold" }}>
          Önümüzdeki günlerde rastgele soru eklenecek kategoriler:
        </span>

        <Table aria-label="simple table">
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderCategoryTable2 = () => {
    const {
      randomCategorySubsetSize,
      randomCategorySubsetSizeOnRemote,
      categories,
    } = this.state;

    let type2CategoryCount =0;

    const rows = categories.map((c) => {
      if(c.questionSelectionMethod ===2)
          type2CategoryCount++;


      return (
        <TableRow key={c.name} style={{}}>
          <TableCell style={{ padding: 0, fontSize: "14pt", width: 300 }}>
            {c.name}
          </TableCell>

          <TableCell style={{ padding: 0 }} align="center">
            <Radio
              checked={c.questionSelectionMethod === 1}
              onChange={(e) =>
                this.updateCategoryOnDb({
                  name: c.name,
                  questionSelectionMethod: 1,
                })
              }
            />
          </TableCell>
          <TableCell style={{ padding: 0 }} align="center">
            <Radio
              checked={c.questionSelectionMethod === 2}
              onChange={(e) =>
                this.updateCategoryOnDb({
                  name: c.name,
                  questionSelectionMethod: 2,
                })
              }
            />
          </TableCell>
          <TableCell style={{ padding: 0 }} align="center">
            <Radio
              checked={c.questionSelectionMethod === 3}
              onChange={(e) =>
                this.updateCategoryOnDb({
                  name: c.name,
                  questionSelectionMethod: 3,
                })
              }
            />
          </TableCell>
        </TableRow>
      );
    });

    const xInput = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          X:{" "}
          <TextField
            style={{ width: 70 }}
            type="number"
            variant="outlined"
            value={this.state.randomCategorySubsetSize}
            onChange={(event) => {
              const newVal = event.target.value;
              this.setState({
                randomCategorySubsetSize: newVal,
              });
            }}
            fullWidth={false}
            size={"small"}
          />
        </div>

        {randomCategorySubsetSize.toString() !==
          randomCategorySubsetSizeOnRemote.toString() && (
            <Button
              variant="contained"
              color="secondary"
              onClick={this.updateSettingsTable}
              style={{ marginTop: 10 }}
            >
              Kaydet
            </Button>
          )}
      </div>
    );

    return (
      <TableContainer style={{ width: "900px" }}>
        <span
          style={{ fontWeight: "bold", fontSize: "22px", marginBottom: 10 }}
        >
          Kategoriler için soru seçme sistemini belirleyiniz:
        </span>

        <Table aria-label="simple table">
          <TableHead // ***************************************** COLUMN HEADERS ******************
            style={{ color: "##505050" }}
          >
            <TableRow style={{ padding: 0 }}>
              <TableCell></TableCell>
              <TableCell align="center">
                Yayın Tarihi Manuel Belirlenen Soruların Kategorileri
              </TableCell>
              <TableCell align="center">
                {"Her Gün Mutlaka Soru Seçilecek Kategoriler (" + type2CategoryCount + ")"}
              </TableCell>
              <TableCell align="center">
                Hergün Aşağıdaki X Adet Kategoriden Rastgele 1 Soru Seçilecek.
                {xInput}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderButtonForDeveloper = () => {
    //NOT: Bu buton, sisteme batch insert vb. developer tarafından yapılan işlemleri tetiklemek için koyuldu. Normal kullanıcı bunu görmemeli. Sadece developer için lazım.
    // İş bitince butonu gizlemeyi unutma yoksa veri tabanı cortlayabilir.

    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          // this.writeServerTime();
          // batchInsertForDeveloper();
          // bachUpdateForDeveloper();
          // this.dbQueryForDeveloper();
          // categoryUpdatesForDeveloper();

        }}
      >
        SAKIN BU BUTONA BASMAYIN !!!
      </Button>
    );
  };

  render() {
    return (
      <div>
        {/* {this.renderButtonForDeveloper()} */}
        {/* {this.renderCategoryTable()} */}
        {this.renderCategoryTable2()}
        {this.state.isLoading && loadingIndicator}
      </div>
    );
  }
}

export default Settings;

const batchInsertForDeveloper = async () => {

  const names = [
    // ['Altıgen - Random', 'altigen'],
  // ['Anagramlar - Random', 'anagramlar'],
  // ['Apartmanlar - Random', 'apartmanlar'],
  // ['Bulmaca Oluştur - Random', 'bulmaca_olustur'],
  // ['Çemberler-Klişe', 'cemberler'],
  // ['Cümle Bul - Random', 'cumle_bul'],
  // ['Dikdörtgenler-Klişe', 'dikdortgenler'],
  // ['Doğrucu-Yalancı-Klişe', 'dogrucu_yalanci'],
  // ['Domino - Klişe', 'domino'],
  // ['Duvar - Klişe', 'duvar'],
  // ['Eksik Harfler - Random', 'eksik_harfler'],
  // ['Eşitsizlik - Random', 'esitsizlik'],
  // ['Fenerler - Klişe', 'fenerler'],
  // ['Gruplar - Random', 'gruplar'],
  // ['Harf Kutusu - Random', 'harf_kutusu'],
  // ['Harf Seç - Random', 'harf_sec'],
  // ['Harfler - Random', 'harfler'],
  // ['Harfmatik - Random', 'harfmatik'],
  // ['Hedef Tahtasi - Klişe', 'hedef_tahtasi'],

  // ['İşlem Tablosu - Klişe', 'islem_tablosu'],
  // ['İşlem Turu - Random', 'islem_turu'],
  // ['Kibrit İşlem - Random', 'kibrit_islem'],
  // ['Köşeler - Random', 'koseler'],
  // ['Küp - Random', 'kup'],

  // ['Küp Bloklar - Random', 'kup_bloklar'],
  // ['Kutu Sil - Random', 'kutu_sil'],
  // ['Kutular - Random', 'kutular'],
  // ['Lambalar - Random', 'lambalar'],
  // ['Masalar - Random', 'masalar'],

  // ['Ok Parçaları - Random', 'ok_parcalari'],
  // ['Oklar - Random', 'oklar'],
  // ['Piramit - Random', 'piramit'],
  // ['Puan Tablosu - Klişe', 'puan_tablosu'],
  // ['Rakam Değiştir - Random', 'rakam_degistir'],

  // ['Rakam Tablosu - Random', 'rakam_tablosu'], // ----------------------------- subtitle1'de sorun var.
   //['Sayı Bilmecesi - Random', 'sayi_bilmecesi'],

  // ['Sayı Blokları - Random', 'sayi_bloklari'],
  // ['Şifreli Sözcük - Klişe', 'sifreli_sozcuk'],
  // ['Şıklar - Random', 'siklar'],

  // ['Silinen Harfler - Random', 'silinen_harfler'],
  // ['Sözcük İçinde Sözcük - Random', 'soz_ic_soz'],
  // ['Sözcük Tahmini - Random', 'sozcuk_tahmini'],
  // ['Sözcük Turu - Random', 'sozcuk_turu'],
  // ['Sözcük Yerleştir - Random', 'sozcuk_yerlestir'],
  // ['Tamamla - Random', 'tamamla'],

  // ['Topla Topla - Random', 'topla_topla'],
  // ['Uçaklar - Random', 'ucaklar'],
  // ['Yirmi Dokuz Harf - Random', 'yirmi_dokuz_harf'],
  // ['Yol - Random', 'yol'],
  // ['Sözcük İçinde Sözcük - Random', 'sozcuk_icinde_sozcuk'],
  // ['Bulmaca Oluştur - Random', 'bulmaca_olusturl']
  ];


  let updates = {};
  const bas=1;
  const son=73;

  for (let name_index = 0; name_index < names.length; name_index++) {
    const n = names[name_index];
    const kategori_adi = n[0];
    const dosya_prefix = n[1];
    console.log("------------ " + kategori_adi + "---------------------");

    let benzer_soru = await firebaseDatabase
      .ref("questions")
      .orderByChild("category")
      .equalTo(kategori_adi)
      .limitToFirst(1)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          const keys = Object.keys(snapshot.val());
          return snapshot.val()[keys[0]];
        }
      });


    let benzer_cevap = await firebaseDatabase
      .ref("answers")
      .orderByChild("category")
      .equalTo(kategori_adi)
      .limitToFirst(1)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          const keys = Object.keys(snapshot.val());
          return snapshot.val()[keys[0]];
        }
      });

    if (benzer_soru && benzer_cevap) {
      let ornek_gorseli_adi = dosya_prefix + "_ornek";

      let sample_path = "";
      try {

        if (dosya_prefix === "bulmaca_olusturl")
          sample_path = await firebaseStorage
            .ref()
            .child("bigContainer/" + "bulmaca_olustur_ornek" + ".png").getDownloadURL();
        else
          sample_path = await firebaseStorage
            .ref()
            .child("bigContainer/" + ornek_gorseli_adi + ".png").getDownloadURL();
      } catch (error) {

      }


      if (!sample_path && kategori_adi !== "Şıklar - Random") {
        console.log("Örnek görseli eksik: " + kategori_adi);

        return;
      }


      for (let i = bas; i < son; i++) {
        const no = await getNoFromSequence();

        let soru_gorseli_adi = dosya_prefix;
        if (i < 10)
          soru_gorseli_adi += "_0" + i;
        else
          soru_gorseli_adi += "_" + i;

        let cevap_gorseli_adi = soru_gorseli_adi + "c";


        // 3 dosya da varsa soruyu gömebiliriz.


        let q_path = false;

        let a_path = false;


        try {
          q_path = await firebaseStorage
            .ref()
            .child("bigContainer/" + soru_gorseli_adi + ".png").getDownloadURL().then();

          a_path = await firebaseStorage
            .ref()
            .child("bigContainer/" + cevap_gorseli_adi + ".png").getDownloadURL();
        } catch (err) {
          console.log("soru ya da cevap görseli storage'da yok: " + kategori_adi);
        }



        if (q_path && a_path && (sample_path || kategori_adi === "Şıklar - Random")) { // 3 görsel de var ve soruyu ve cevabı insert edebiliriz:

          let dbKey = "posta_gazetesi_" + dosya_prefix + "_" + i;
         await firebaseDatabase.ref("questions").child(dbKey).push();
         await firebaseDatabase.ref("answers").child(dbKey).push();

          updates["/questions/" + dbKey + "/category"] = kategori_adi;
          updates["/questions/" + dbKey + "/imageSource"] = q_path;
          updates["/questions/" + dbKey + "/order"] = 1;
          updates["/questions/" + dbKey + "/no"] = no;
          updates["/questions/" + dbKey + "/subtitle1"] = (benzer_soru.subtitle1?benzer_soru.subtitle1 : "" );
          updates["/questions/" + dbKey + "/title"] = benzer_soru.title;
          updates["/questions/" + dbKey + "/sampleImageSource"] = sample_path? sample_path : "" ;
          updates["/questions/" + dbKey + "/sampleText"] = benzer_soru.sampleText? benzer_soru.sampleText : "" ;
          updates["/questions/" + dbKey + "/firstPublishDate"] = "01.07.2020";
          updates["/questions/" + dbKey + "/firstPublishPlace"] = "Posta Gazetesi";


          updates["/answers/" + dbKey + "/no"] = no;
          updates["/answers/" + dbKey + "/imageSource"] = a_path;
          updates["/answers/" + dbKey + "/category"] = kategori_adi;
          updates["/answers/" + dbKey + "/order"] = 1;
          updates["/answers/" + dbKey + "/title"] = benzer_cevap.title;





        }
      }
    }
    else {
      console.log("Bu kategoriden benzer soru bulamadım: " + kategori_adi);
    }
  }

 console.log("-------  UPDATES HAZIRLANDI ----");

  firebaseDatabase
    .ref()
    .update(updates)
    .then(() => {
      console.log("--- cevap eklendi. baş-son: " + bas + " - " + son );
    });


  // const namePrefix = "sozcuktahmini-00";
  // const categoryName = "Sözcük Tahmini - Klişe";
  // const subtitle1 =
  //   "Dört farklı harften oluşan bir sözcüğü bulmak için tablodaki tahminler yapılmıştır. Tahminlerin yanındaki her “+” işareti, doğru yerde bulunan bir harfe, her “-“ işareti ise yanlış yerde bulunan bir harfe karşılık gelmektedir. Bu bilgileri kullanarak sözcüğü bulunuz.";

  // const sampleImageSource =
  //   "https://firebasestorage.googleapis.com/v0/b/akil-zeka-oyunlari.appspot.com/o/sampleImages%2F-MARbDbuHBKmwxHN9lS1?alt=media&token=bb67ea2e-6b80-4553-b7a1-e15332b64a9f";
  // const sampleText = "Örnek:";
  // const title = "Sözcük Tahmini";
  // const imageFileExtension = ".png";

  // for (let i = 2; i < 100; i++) {
  //   const dbKey = i < 10 ? namePrefix + "0" + i : namePrefix + i;

  //   //****************  QUESTİON EKLEME */

  //   const storageRef = firebaseStorage
  //     .ref()
  //     .child("questionImages" + "/" + dbKey + imageFileExtension);

  //   storageRef.getDownloadURL().then(function (downloadURL) {
  //     const questionImageSource = downloadURL;

  //     let updates = {};
  //     updates["/questions/" + dbKey + "/category"] = categoryName;
  //     updates["/questions/" + dbKey + "/imageSource"] = questionImageSource;
  //     updates["/questions/" + dbKey + "/order"] = 1;
  //     updates["/questions/" + dbKey + "/subtitle1"] = subtitle1;
  //     updates["/questions/" + dbKey + "/title"] = title;
  //     updates["/questions/" + dbKey + "/sampleImageSource"] = sampleImageSource;
  //     updates["/questions/" + dbKey + "/sampleText"] = sampleText;

  //     firebaseDatabase
  //       .ref()
  //       .update(updates)
  //       .then(() => {
  //         console.log("soru eklendi: ", i);
  //       });
  //   });

  //   // ********************** ANSWER EKLEME
  //   const storageRef2 = firebaseStorage
  //     .ref()
  //     .child("answerImages" + "/" + dbKey + "-c" + imageFileExtension);

  //   storageRef2.getDownloadURL().then(function (downloadURL) {
  //     const answerImageSource = downloadURL;

  //     let updates = {};
  //     updates["/answers/" + dbKey + "/imageSource"] = answerImageSource;
  //     updates["/answers/" + dbKey + "/order"] = 1;
  //     updates["/answers/" + dbKey + "/title"] = title;

  //     firebaseDatabase
  //       .ref()
  //       .update(updates)
  //       .then(() => {
  //         console.log("cevap eklendi: ", i);
  //       });
  // });
  // }
};

const bachUpdateForDeveloper = async () => {
  await firebaseDatabase
    .ref("questions")
    .orderByChild("category")
    .equalTo("Altıgen-Klişe")
    .once("value")
    .then(async (snapshot) => {
      if (snapshot.val()) {
        // getSetSequence
        const keys = Object.keys(snapshot.val());
        // console.log("18'nin soruları : ", snapshot.val());
        let updates = {};

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          let q=snapshot.val()[key];
          
        }

        // console.log(`updates: `, updates);


        await firebaseDatabase.ref().update(updates).then(_=>console.log("---bitti---"));

        // const sequenceHead = await getSetSequence(keys.length);
        // let updates = {};

        // for (let i = 0; i < keys.length; i++) {
        //   const key = keys[i];

        //   updates["/questions/" + key + "/no"] = sequenceHead + i;
        //   updates["/answers/" + key + "/no"] = sequenceHead + i;
        // }

        // await firebaseDatabase.ref().update(updates);

        // Object.keys(snapshot.val()).map(async function (key) {
        //   let questionObject = snapshot.val()[key];
        //   questionObject["dbKey"] = key;

        //   if (true) {
        //     const no = await getNoFromSequence();
        //     console.log("no: ", no);

        //     if (!isNaN(no)) {
        //       let updates = {};
        //       updates["/questions/" + key + "/no"] = no;
        //       updates["/answers/" + key + "/no"] = no;
        //       await firebaseDatabase.ref().update(updates);
        //     }
        //   }

        //   return questionObject;
        // });
      }
    });
};

const dbQueryForDeveloper = async () => {
  const result = await firebaseDatabase
    .ref("questions")
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        let catDateObj = {};
        const resObj = snapshot.val();

        Object.keys(resObj).forEach((key) => {
          // console.log(key);
          let qObj = resObj[key];
          let catDateStr = qObj.category + qObj.publishDate;

          let arrayOfCatDateStr = catDateObj[catDateStr];
          if (arrayOfCatDateStr) arrayOfCatDateStr.push("2 tane");
          else {
            catDateObj[catDateStr] = [];
          }
        });

        console.log(catDateObj);
        Object.keys(catDateObj).forEach((key) => {
          const a = catDateObj[key];
          if (a.length > 1) console.log(key + " : ", catDateObj[key]);
        });
      }
    });
};

const categoryUpdatesForDeveloper = async () => {
  await firebaseDatabase
    .ref("categories")
    .once("value")
    .then(async (snapshot) => {
      if (snapshot.val()) {
        let updates = {};
        Object.keys(snapshot.val()).forEach((uid) => {
          updates["/categories/" + uid + "/questionSelectionMethod"] = 1;
        });

        await firebaseDatabase
          .ref()
          .update(updates)
          .then((res) => alert("ok"));
      }
    });
};

