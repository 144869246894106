import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

import firebase from 'firebase/app';

const config = {
  apiKey: "AIzaSyCOEJSjT4PTElQh1k-z_3mmWan_p_5mP20",
  authDomain: "akil-zeka-oyunlari.firebaseapp.com",
  databaseURL: "https://akil-zeka-oyunlari.firebaseio.com",
  // databaseURL: "http://localhost:9000/?ns=akil-zeka-oyunlari",
  projectId: "akil-zeka-oyunlari",
  storageBucket: "akil-zeka-oyunlari.appspot.com",
  messagingSenderId: "640766877666",
  appId: "1:640766877666:web:5c1c338657babdcb12a3bb"
};

firebase.initializeApp(config);
export const firebaseDatabase = firebase.database();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();

