import React, { Component } from "react";
import { Button, withStyles, Collapse, Grid } from "@material-ui/core";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import MenuIcon from "@material-ui/icons/Menu";
import Urls from "../constants/URLConstants";
import { Link } from "react-router-dom";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "3rem",
    marginTop: "3rem",
  },
  narrowWindowBox: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    flexGrow: 1,
    marginBottom: "2rem",
    marginTop: "2rem",
  },

  mediaButton: {
    marginTop: "7px",
    padding: "5px",
    width: "calc(25px + 0.9vw )",
    height: "calc(25px + 0.9vw )",
    minWidth: "unset",
    backgroundColor: "#F2F2F2",
    borderRadius: "7px",
    display: "flex",
    alignContent: "center",
  },
});

class GlobalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowIsLarge: true,
      verticalMenuVisible: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 700 && this.state.windowIsLarge)
      this.setState({ windowIsLarge: false });
    else if (window.innerWidth >= 700 && !this.state.windowIsLarge)
      this.setState({ windowIsLarge: true });
  };

  renderLogo = () => {
    return (
      <img src="gri-logo.svg" alt="-" style={{ width: "calc(100px + 7vw)" }} />
    );
  };

  renderHeaderAndNavigator = () => {
    const windowIsLarge = this.state.windowIsLarge;
    const verticalMenuVisible = this.state.verticalMenuVisible;
    const path = this.props.path;

    const renderNavigator = () => {
      const renderLink = (text, url, isExternalLink) => {
        let isActive = path === url;
        if (path === "/" && url === Urls.questions) isActive = true;

        const linkStyle = {
          color: "#0090A1",
          textDecoration: "none",
          fontWeight: isActive ? "800" : "500",
          marginRight: "1vw",
          marginLeft: "1vw",
          fontSize: windowIsLarge ? "calc(1rem + 0.5vw)" : "1.3rem",
          marginTop: windowIsLarge ? 0 : "3vh",
        };

        if (isExternalLink) {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
              href={url}
            >
              {text}
            </a>
          );
        } else
          return (
            <Link style={linkStyle} to={url}>
              {text}
            </Link>
          );
      };

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1vh",
            flexDirection: windowIsLarge ? "row" : "column",
          }}
        >
          {/* {renderLink("Yönetim", Urls.admin.add)} */}
          {renderLink("Günün Soruları", Urls.questions)}
          {/* {renderLink("Dünün Cevapları", Urls.answers)} */}
          {renderLink("griceviz.com", "https://griceviz.com/", true)}
        </div>
      );
    };

    const gricevizText = (
      <div
        style={{
          fontSize: "calc(0.9rem + 1.8vw)",
          color: "#0090A1",
          fontFamily: "Arial",
        }}
      >
        GriCeviz
      </div>
    );

    const menuButton = (
      <div
        style={{
          backgroundColor: "#F2F2F2",
          marginRight: "16px",
          padding: "8px",
          borderRadius: "11px",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          this.setState({ verticalMenuVisible: !verticalMenuVisible });
        }}
      >
        <MenuIcon
          style={{
            fontSize: "2rem",
            transform: verticalMenuVisible ? "rotate(90deg)" : "rotate(0deg)",
          }}
        />
      </div>
    );

    const headerText = (
      <span
        style={{
          fontSize: windowIsLarge ? "calc(1rem + 2vw)" : "calc(0.9rem + 1.8vw)",
          color: "#01082D",
        }}
      >
        <span style={{ fontWeight: "900" }}>AKIL </span>
        <span style={{ fontWeight: "500" }}>VE </span>
        <span style={{ fontWeight: "900" }}> ZEKÂ </span>
        <span style={{ fontWeight: "500" }}>OYUNLARI</span>
      </span>
    );

    if (windowIsLarge) {
      return (
        <div style={{ textAlign: "center", letterSpacing: "-1pt" }}>
          {headerText}
          {renderNavigator()}
        </div>
      );
    } else {
      return (
        <Grid item>
          <div style={{ textAlign: "center", letterSpacing: "-1pt" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {menuButton}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {gricevizText}
                {headerText}
              </div>
            </div>

            <Collapse in={verticalMenuVisible} collapsedHeight={0}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {renderNavigator()}
                {this.renderSocialMediaLinks()}
              </div>
            </Collapse>
          </div>
        </Grid>
      );
    }
  };

  renderSocialMediaLinks = () => {
    const windowIsLarge = this.state.windowIsLarge;
    const { classes } = this.props;

    const mediaLink = (mediaIcon, url) => {
      return (
        <a
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        <Button
          variant="contained"
          className={classes.mediaButton}
        >
          {mediaIcon}
        </Button>
        </a>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: windowIsLarge ? "flex-end" : "center",
          margin: windowIsLarge ? 0 : "30px",
        }}
      >
        <span
          style={{
            fontSize: windowIsLarge ? "calc(0.8rem + 0.7vw)" : "1.3rem",
            color: "#01082D",
            fontWeight: "600",
          }}
        >
          Emrehan Halıcı
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1vh",
          }}
        >
          {mediaLink(
            <InstagramIcon
              style={{ color: "#DD3E79", fontSize: "calc(1rem + 0.4vw)" }}
            /> , "https://www.instagram.com/emrehanhalici"
          )}
          <div style={{ marginRight: "14px", marginLeft: "14px" }}>
            {mediaLink(
              <TwitterIcon
                style={{ color: "#2CA5DA", fontSize: "calc(1rem + 0.4vw)" }}
              />, "https://twitter.com/emrehanhalici"
            )}
          </div>
          {mediaLink(
            <FacebookIcon
              style={{ color: "#2468AD", fontSize: "calc(1rem + 0.4vw)" }}
            />,"https://tr-tr.facebook.com/emrehan.halici"
          )}
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const windowIsLarge = this.state.windowIsLarge;

    if (windowIsLarge) {
      return (
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={3}>
              {this.renderLogo()}
            </Grid>
            <Grid item xs={6}>
              {this.renderHeaderAndNavigator()}
            </Grid>
            <Grid item xs={3}>
              {this.renderSocialMediaLinks()}
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={classes.narrowWindowBox}>
          {this.renderHeaderAndNavigator()}
        </div>
      );
    }
  }
}

export default withStyles(useStyles)(GlobalHeader);
