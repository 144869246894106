import React, { Component } from "react";
import {
  pullCategories,
  pullFilteredQuestions,
} from "../../db/FirebaseMethods";
import {
  TextField,
  Radio,
  NativeSelect,
  Button,
  Backdrop,
  CircularProgress,
  Slider,
  Switch,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import StackGrid from "react-stack-grid";

import trLocale from "date-fns/locale/tr";

import DateFnsUtils from "@date-io/date-fns";
import QuestionCard from "../QuestionCard";

class Archive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "TÜMÜ",
      categories: [],
      publishDate: new Date(),
      dateOption: "all",
      searchText: "",
      searchInTitle: false,
      searchInSubtitle: false,
      searchInQuestionNumber: false,
      pageSize: 30,
      pageNo: 1,
      filteredQuestions: [],
      isFetching: false,
      filteredCount: 0,
    };
  }

  componentDidMount() {
    this.pullCategoriesFromDb();
  }

  pullCategoriesFromDb = async () => {
    const categories = await pullCategories();
    this.setState({ categories });
  };

  renderFilters = () => {
    const categoryList = (
      <NativeSelect
        name="category"
        value={this.state.selectedCategory}
        onChange={(event) =>
          this.setState({
            selectedCategory: event.target.value,
          })
        }
      >
        <option key={"tümü"} value={"TÜMÜ"}>
          {"TÜMÜ"}
        </option>
        {this.state.categories.map(function (item) {
          return (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </NativeSelect>
    );

    const dateOptions = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={this.state.dateOption === "all"}
            value="all"
            onChange={(e) => {
              this.setState({ dateOption: e.target.value });
            }}
          />
          <span>TÜMÜ</span>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={this.state.dateOption === "specificDay"}
            value="specificDay"
            onChange={(e) => {
              this.setState({ dateOption: e.target.value });
            }}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
            <KeyboardDatePicker
              style={{ width: "150px" }}
              margin="normal"
              id="date-picker-dialog"
              // label="Date picker dialog"
              format="dd.MM.yyyy"
              value={this.state.publishDate}
              onChange={(date) => {
                this.setState({
                  publishDate: date,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={this.state.dateOption === "empty"}
            value="empty"
            onChange={(e) => {
              this.setState({ dateOption: e.target.value });
            }}
          />

          <span>Tarih Atanmamış Sorular</span>
        </div>
      </div>
    );

    const textFilter = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>Metin </span>
        <TextField
          variant="outlined"
          value={this.state.searchText || ""}
          onChange={(event) => {
            const newVal = event.target.value;
            this.setState({
              searchText: newVal,
            });
          }}
          fullWidth={false}
          size={"small"}
        />

        <span style={{ marginLeft: "50px" }}>Arama Yeri </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "solid 1px",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            marginLeft: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={this.state.searchInTitle}
              onChange={(event) =>
                this.setState({ searchInTitle: event.target.checked })
              }
            />
            Başlık
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={this.state.searchInSubtitle}
              onChange={(event) =>
                this.setState({ searchInSubtitle: event.target.checked })
              }
            />
            Soru Metni
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={this.state.searchInQuestionNumber}
              onChange={(event) =>
                this.setState({ searchInQuestionNumber: event.target.checked })
              }
            />
            Soru Numarası
          </div>
        </div>
      </div>
    );

    const pageSize = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div className="pageSizeIndicator">{this.state.pageSize}</div>
          <Slider
            value={Number(this.state.pageSize)}
            aria-labelledby="discrete-slider-always"
            step={10}
            min={10}
            max={100}
            style={{ width: 250 }}
            onChange={(event, newValue) => {
              this.setState({
                pageSize: newValue,
              });
            }}
          />
        </div>

        <span style={{ fontStyle: "italic" }}>
          Sayfa yükleme performansını etkiler.
        </span>
      </div>
    );

    return (
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          <FilterBox filterExplanation="Kategori" filterInput={categoryList} />
          <FilterBox
            filterExplanation="Yayın Tarihi"
            filterInput={dateOptions}
          />
          <FilterBox
            filterExplanation="Metin Filtresi"
            filterInput={textFilter}
          />
          <FilterBox
            filterExplanation="1 Sayfada Gösterilecek Soru Sayısı"
            filterInput={pageSize}
          />
          {/* <FilterRow
          filterExplanation="Bu Ekranda Resimleri Gizle"
          filterInput="Daha hızlı sayfa yükleme için..."
        /> */}
        </div>

        <div className="filterButtons">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ pageNo: 1 }, this.fetchfilteredQuestions);
            }}
          >
            UYGULA
          </Button>
        </div>
      </div>
    );
  };

  fetchfilteredQuestions = async () => {
    const {
      selectedCategory,
      publishDate,
      dateOption,
      searchText,
      searchInTitle,
      searchInSubtitle,
      searchInQuestionNumber,
    } = this.state;

    const filter = {
      selectedCategory,
      publishDate,
      dateOption,
      searchText,
      searchInTitle,
      searchInSubtitle,
      searchInQuestionNumber,
    };

    this.setState(
      {
        isFetching: true,
        filteredQuestions: [],
        filteredCount: 0,
      },
      async () => {
        const questions = await pullFilteredQuestions(filter);

        this.setState({
          filteredQuestions: questions,
          filteredCount: questions.length,
          isFetching: false,
        });
      }
    );
  };

  renderQuestions = () => {
    const { filteredCount, pageNo, pageSize, filteredQuestions } = this.state;
    const startIndex = Math.min((pageNo - 1) * pageSize, filteredCount - 1);
    const endIndex = Math.min(startIndex + pageSize, filteredCount);

    let selectedPageQuestions = [];

    if (filteredCount > 0)
      selectedPageQuestions = filteredQuestions.slice(startIndex, endIndex);

    const questionCards = selectedPageQuestions.map(function (item) {
      return <QuestionCard item={item} editible={true} key={item.dbKey} />;
    });

    return (
      <div style={{ width: "100%", marginTop: "30px", border: "solid" }}>
        <StackGrid
          monitorImagesLoaded={true}
          columnWidth={400}
          gutterWidth={15}
        >
          {questionCards}
        </StackGrid>
      </div>
    );
  };

  renderPagination = () => {
    const pageCount = this.state.filteredCount / this.state.pageSize;

    let clickablePageNumbers = [];

    for (let i = 0; i < pageCount; i++) {
      const thisIsCurrentPage = this.state.pageNo === i + 1;
      const clickablePageNo = (
        <span
          style={{
            marginRight: "5px",
            width: "30px",
            borderRadius: "20px",
            textAlign: "center",
            fontWeight: thisIsCurrentPage ? "800" : "500",
            backgroundColor: thisIsCurrentPage ? "#C5E1A5" : "transparent",
            cursor: "pointer",
          }}
          key={i}
          onClick={() => {
            console.log(" Sayfa No:", i + 1);
            this.setState({ pageNo: i + 1 });
          }}
        >
          {i + 1}
        </span>
      );

      clickablePageNumbers.push(clickablePageNo);
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "flex-start",
          marginTop: "20px",
        }}
      >
        <div>Filtrelenen Soru Sayısı : {this.state.filteredCount}</div>

        {this.state.pageSize < this.state.filteredCount && (
          <div style={{ display: "flex" }}>
            Sayfa No:
            {clickablePageNumbers}
          </div>
        )}
      </div>
    );
  };

  renderLoadingIndicator = () => {
    return (
      <Backdrop
        style={{ zIndex: 999, color: "#fff" }}
        open={this.state.isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {this.renderFilters()}
        {this.renderPagination()}
        {this.renderQuestions()}
        {this.renderLoadingIndicator()}
      </div>
    );
  }
}

export default Archive;

function FilterBox(props) {
  return (
    <div className="filterBox">
      <div className="filterExplanation">{props.filterExplanation}</div>

      <div className="filterInputs">{props.filterInput}</div>
    </div>
  );
}
