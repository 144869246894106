// Projenin her tarafında kullanılacak global fonksiyonlar burada.

function convertStringToDate(strDate) {
  // 30.12.2020 formatındaki stringi date objesine çevirir.

  const y = parseInt(strDate.substring(6, 10));
  const m = parseInt(strDate.substring(3, 5)) - 1; // aylar 0-11 arası numaralı olduğu için 1 çıkardık.
  const d = parseInt(strDate.substring(0, 2));
  return new Date(y, m, d, 0, 0, 0, 0);
}

function convertDateToString(dateObject) {
  // vpn'le bağlanmak vb. durumlarda saat farkından dolayı date objeleri db'ye hatalı kaydedilebilir. Bunu engellemek için..
  return dateObject.toLocaleDateString("tr-TR", {
    timeZone: "Europe/Istanbul", month:"2-digit", day:"2-digit", year:"numeric"
  });
}

function addDaysToDate(date, dayNo) {
  let tempDate = new Date(date);
  return new Date(tempDate.setDate(tempDate.getDate() + dayNo));
}

export { convertStringToDate, convertDateToString, addDaysToDate };
