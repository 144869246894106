import React, { Component } from "react";
import {
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import StackGrid from "react-stack-grid";
import QuestionCard from "../QuestionCard";
import AppContext from "../../AppContext";
import "../../css/Styles.css";
import SaveIcon from "@material-ui/icons/Save";
import { saveQuestionOrders } from "../../db/FirebaseMethods";

class Sorting extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      orders: {},
      reorderDate: null,
      windowWidth: 0,
      isLoading: false,
      showSuccessMessage: false,
    };
  }

  componentDidMount() {
    this.context.pullQuestionsFromDb(this.context.selectedDate);
    this.setState({ reorderDate: this.context.selectedDate });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  calculatePorperColumnWidth = () => {
    const w = this.state.windowWidth;
    if (w <= 700) return "100%";
    if (w <= 1200) return "50%";
    return "33%";
  };

  handleChange = (e) => {
    const questionId = e.target.name;
    const newOrder = e.target.value;
    if (!isNaN(newOrder)) {
      let orders = this.state.orders;
      orders[questionId] = newOrder;
      this.setState({ orders });
    }
  };

  saveOrder = () => {
    this.setState({ isLoading: true });
    const onSuccess = () => {
      // alert("Sıralama kaydedildi");
      this.context.pullQuestionsFromDb(this.context.selectedDate);
      this.setState({ orders: {}, isLoading: false, showSuccessMessage: true });
    };

    const onFail = () => {
      this.context.pullQuestionsFromDb(this.context.selectedDate);
      this.setState({ isLoading: false });
      alert("HATA: Sıralama kaydedilemedi..!");
    };
    saveQuestionOrders(this.state.orders).then(onSuccess, onFail);
  };

  sortAsPreview = () => {
    this.context.reorderQuestions(this.state.orders);
    this.grid.updateLayout(); // item'ların üst üste binmesini engellemek için tekrar yerleşim yaptırıyoruz.
  };

  renderLoadingIndicator = () => {
    return (
      <Backdrop
        style={{ zIndex: 999, color: "#fff" }}
        open={this.state.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  renderFeedBack = () => {
    const handleClose = () => this.setState({ showSuccessMessage: false });

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={this.state.showSuccessMessage}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Kayıt Başarılı"
      />
    );
  };

  render() {
    const reorderDate = this.state.reorderDate;
    const selectedDate = this.context.selectedDate;

    if (reorderDate !== selectedDate)
      this.setState({ reorderDate: selectedDate, orders: {} });
    // buradaki kontrol olası bir misbehaviour'ın önüne geçmek için eklendi:
    // örneğin ekranda sıralamalar değiştirildi ama kaydet butonuna basılmadı. (vazgeçildi). Sonra tarih değiştirildi.
    // yeni tarihteki sıralama da değiştirildi ve bu sefer kayıt edildi. İnternal state'teki eski sıralama emirleri işleme alınmasın diye
    // tarih değişir değişmez eski sıralama emirlerini sıfırladık.

    let questions = this.context.questions.map((item) => {
      return (
        <div key={item.dbKey}>
          <QuestionCard key={item.dbKey} item={item} />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "white",
            }}
          >
            <TextField
              id="outlined-name"
              label="Sıra No"
              name={item.dbKey}
              onChange={this.handleChange}
              variant="outlined"
              type="number"
              // value={item.order}
              defaultValue={item.order}
              maxWidth={"30px"}
              size="small"
              style={{ width: "80px" }}
            />
          </div>
        </div>
      );
    });

    const previewButton = (
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={this.sortAsPreview}
      >
        <b>Sırala (Önizleme)</b>
      </Button>
    );

    const saveButton = (
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={this.saveOrder}
        startIcon={<SaveIcon />}
      >
        <b>Sıralamayı Kaydet</b>
      </Button>
    );

    return (
      <div>
        <div className="sortingButtonsBox">
          {previewButton}
          {saveButton}
        </div>
        <StackGrid
          monitorImagesLoaded={true}
          columnWidth={this.calculatePorperColumnWidth()}
          gutterWidth={15}
          duration={0}
          gridRef={(grid) => (this.grid = grid)}
        >
          {questions}
        </StackGrid>
        {this.renderLoadingIndicator()}
        {this.renderFeedBack()}
      </div>
    );
  }
}

export default Sorting;
