import React, { Component } from "react";
import { Route } from "react-router-dom";

import AppContext from "../AppContext";
import Login from "./admin/Login";

class RouteGenerator extends Component {
  static contextType = AppContext;

  takeProperComponent = (history) => {
    const permissionDenied =
      this.props.onlyForAdmin && !this.context.userIsAnAdmin;

    if (permissionDenied) return <Login />;
    else return this.props.component;
  };

  render() {
    const path= this.props.path;
    
    return (
      <Route
        key={path}
        path={path}
        exact
        component={this.takeProperComponent}
      />
    );
  }
}

export default RouteGenerator;
