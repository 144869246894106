import React, { Component } from "react";

import {
  Dialog,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";



import QuestionCard from "./QuestionCard";
import "../css/Styles.css";

import AppContext from "../AppContext";


class AnswerModal extends Component {
  static contextType = AppContext;




  handleClose = () => {
    this.props.onClose();
  };



  renderLoadingIndicator = () => {
    return (
      <Backdrop
        style={{ zIndex: 999, color: "#fff" }}
        open={this.state.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  render() {
    const answerIsPublished = this.props.item;
    return (
      <Dialog
        // disableBackdropClick={true}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        width={"sm"}
        fullWidth={true}

      // fullScreen={true}
      >
        <div style={{ padding: 35 }}>
          {answerIsPublished && <div style={{ fontSize: "1.3em", fontWeight: "bold", marginBottom: 12, textAlign: "center" }}>CEVAP</div>}
          {answerIsPublished && <QuestionCard
            key={this.props.dbKey}
            editible={false}
            item={this.props.item}
            type={"a"}
          />}
          {!answerIsPublished && "Bu sorunun cevabı yarın yayınlanacaktır..."}
        </div>
      </Dialog>
    );
  }
}

export default AnswerModal;

