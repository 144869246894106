import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import StackGrid from "react-stack-grid";

import QuestionCard from "./QuestionCard";

import AppContext from "../AppContext";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  links: {
    marginRight: "15px",
    color: "#0090A1",
    fontSize: "24px",
    textDecoration: "none",
  },
});

class Questions extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // this.context.pullQuestionsFromDb(this.context.selectedDate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  calculatePorperColumnWidth = () => {
    const w = this.state.windowWidth;
    if (w <= 700) return "100%";
    if (w <= 1200) return "50%";
    return "33%";
  };

  renderQuestionCards = () => {
    const editible = this.context.userIsAnAdmin;
    return this.context.questions.map(function (item) {
      return <QuestionCard key={item.dbKey} item={item} editible={editible} type={"q"} />;
    });
  };

  renderLoadingPanel = () => {
    return (
      <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  };

  render() {
    if (this.context.isPullingQuestions) return this.renderLoadingPanel();
    return (
      <StackGrid
        columnWidth={this.calculatePorperColumnWidth()}
        gutterWidth={25}
        monitorImagesLoaded={true}
      >
        {this.renderQuestionCards()}
      </StackGrid>
    );
  }
}

export default withStyles(useStyles)(Questions);
