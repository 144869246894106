import {
  firebaseDatabase,
  firebaseAuth,
  firebaseStorage,
} from "../config/firebaseConfig";

import {
  convertStringToDate,
  convertDateToString,
  addDaysToDate,
} from "../HelperFunctions";
import firebase from "firebase/app";

const pullQuestions = async (questionDate) => {
  // Verilen tarihteki soruları çeker. Ayrıca hergün yayınlanmak üzere set edilmiş soru/banner'ları da çeker.
  // random seçilmek üzere set edilen kategorilerden henüz soru seçilmemişse o kategorilerden de rastgele soru seçerek listeye dahil eder.

  // const todaysQuestions = await pullSpecificDayQuestions(questionDate);
  // const everydaysQuestions = await pullEveryDayQuestions();

  const [todaysQuestions, everydaysQuestions ]  = await Promise.all([
    pullSpecificDayQuestions(questionDate),
    pullEveryDayQuestions()
  ]);

  const questions = todaysQuestions.concat(everydaysQuestions);

  // await addRandomQuestionsIfNeeded(questions, questionDate);

  questions.sort(function (first, second) {
    return first.order - second.order;
  });

  return questions;
};

const pullFilteredQuestions = async (filter) => {
  const {
    selectedCategory,
    publishDate,
    dateOption, // all, specificDay, empty
  } = filter;

  let questions = [];

  //Firebase'de SQL gibi where xx and yy and zz... şeklinde kombine sorgu sistemi yok. where içinde tek bir koşul yazıp client'ta filtrelemeye izin var.
  // Bu nedenle db'den gelecek ham data'yı azaltmak için 3 farklı sorgu yazıyorum:
  // tarih seçiliyse o tarihteki soruları çeker, kategori varsa o kategoriyi çeker, ikisi de belirtilmemişse tüm soruları çeker.
  // DB'den çekilen sorular client'ta filtrelenecek ayrıca.

  if (dateOption === "specificDay")
    questions = await pullSpecificDayQuestions(publishDate);
  else if (selectedCategory !== "TÜMÜ")
    questions = await pullCategoryQuestions(selectedCategory);
  else questions = await pullAllQuestions();

  // DB'den sadece 1 koşul dikkate alınarak birsürü soru geldi. Şimdi bunları daha detaylı filtreleyelim:
  questions = filtrateQuestions(questions, filter);

  return questions;
};

const pullAnswers = async (answerDate) => {
  let answers = [];

  await firebaseDatabase
    .ref("answers")
    .orderByChild("publishDate")
    .equalTo(convertDateToString(answerDate))
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        const keys = Object.keys(snapshot.val());

        for (let i = 0; i < keys.length; i++) {
          const answer = snapshot.val()[keys[i]];
          answer["dbKey"] = keys[i];

          if (answer.subtitle1 || answer.imageSource)
            // Cevap metni ve görseli olmayan soruların cevabı ekrana gelmeyecek. (banner vb. durumlarda)
            answers.push(answer);
        }
        answers.sort(function (first, second) {
          return first.order - second.order;
        });
      }
    });

  return answers;
};

const pullQuestionByKey = async (dbKey) => {
  let q = {};

  await firebaseDatabase
    .ref("questions")
    .child(dbKey)
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        q = snapshot.val();
      }
    });

  return q;
};

const pullAnswerByKey = async (dbKey) => {
  let q = {};

  await firebaseDatabase
    .ref("answers")
    .child(dbKey)
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        q = snapshot.val();
      }
    });

  return q;
};

const pullSampleQuestionByCategory = async (ctgr) => {
  let q = {};

  await firebaseDatabase
    .ref("questions")
    .orderByChild("category")
    .equalTo(ctgr)
    .limitToLast(1)
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        q = snapshot.val();
      }
    });

  return q;
};

// const pullCategories = async () => {
//   let categories = [];

//   await firebaseDatabase
//     .ref("categories")
//     // .orderByChild("publishDate")
//     .once("value")
//     .then((snapshot) => {
//       if (snapshot.val()) {
//         categories = Object.keys(snapshot.val()).map(function (key) {
//           let c = {...snapshot.val()[key]};
//           c.key = key;

//           return c;
//         });
//       }
//     });

//   return categories;
// };

const pullCategories = async () => {
  let categories = [];

  await firebaseDatabase
    .ref("categories")
    // .orderByChild("publishDate")
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        categories = Object.keys(snapshot.val()).map((key) => {
          let c = snapshot.val()[key];
          c.key = key;
          c.name = key;

          return c;
        });
      }
    });

  return categories;
};

const updateQuestionAndAnswer = async (
  dbKey,
  question,
  questionImageFile,
  answer,
  answerImageFile,
  headerImageFile,
  sampleImageFile
) => {
  addCategoryIfNotExists(question.category);

  if (!question.no) {
    // hala numarası yoksa bi de sıradan numara verelim boş kalmasın.
    const no = await getNoFromSequence();
    if (!isNaN(no)) {
      question.no = no;
      answer.no = no;
    }
  }

  if (questionImageFile) {
    question.imageSource = await writeImageToStorage({
      file: questionImageFile,
      dbKey: dbKey,
      fileStoragePath: "questionImages",
    });
  }

  if (headerImageFile) {
    question.headerImageSource = await writeImageToStorage({
      file: headerImageFile,
      dbKey: dbKey,
      fileStoragePath: "headerImages",
    });
  }

  if (sampleImageFile) {
    question.sampleImageSource = await writeImageToStorage({
      file: sampleImageFile,
      dbKey: dbKey,
      fileStoragePath: "sampleImages",
    });
  }

  if (answerImageFile) {
    answer.imageSource = await writeImageToStorage({
      file: answerImageFile,
      dbKey: dbKey,
      fileStoragePath: "answerImages",
      // errorMessage: "HATA: Cevap resmi yüklenirken birşeyler ters gitti..!",
    });
  }

  let updates = {};
  updates["/questions/" + dbKey] = question;
  updates["/answers/" + dbKey] = answer;

  return firebaseDatabase.ref().update(updates);
};

const updateCategory = async (category) => {
  let updates = {};

  updates["/categories/" + category.name + "/questionSelectionMethod"] =
    category.questionSelectionMethod;

  return await firebaseDatabase.ref().update(updates);
};

const insertNewQuestionAndAnswer = async (
  question,
  questionImageFile,
  answer,
  answerImageFile,
  headerImageFile,
  sampleImageFile
) => {
  // if (!question.publishDate)
  //   question.publishDate = new Date().toLocaleDateString("tr-TR");
  // if (!answer.publishDate)
  //   answer.publishDate = addDaysToDate(new Date(), 1).toLocaleDateString(
  //     "tr-TR"
  //   ); // ertesi günü

  const no = await getNoFromSequence();
  if (!isNaN(no)) {
    question.no = no;
    answer.no = no;
  }

  question.order = 1;
  answer.order = 1;

  const dbKey = firebaseDatabase.ref().child("questions").push().key;

  await updateQuestionAndAnswer(
    dbKey,
    question,
    questionImageFile,
    answer,
    answerImageFile,
    headerImageFile,
    sampleImageFile
  );
};

const getUser = async (mail, pass) => {
  let user = {};

  await firebaseAuth
    .signInWithEmailAndPassword(mail, pass)
    .then((res) => {
      user = res.user;
    })
    .catch((error) => {
      console.log(error.message);
      alert(error.message);
    });

  return user;
};

const listenAuthenticatedUserChanges = (afterMethod) => {
  firebaseAuth.onAuthStateChanged((user) => {
    afterMethod(user);
  });
};

const logout = () => {
  firebaseAuth.signOut().then(
    function () {
      // Sign-out successful.
    },
    function (error) {
      alert("Logut esnasında hata oldu: ", error);
    }
  );
};

const saveQuestionOrders = async (orders) => {
  let updates = {};

  for (let [key, value] of Object.entries(orders)) {
    updates["/questions/" + key + "/order"] = value;
    updates["/answers/" + key + "/order"] = value;
  }

  return firebaseDatabase.ref().update(updates);
};

const getServerDate = async () => {
  // kullanıcı bilgisayarının tarihini değiştirdiğinde new Date() kullandığımız yerler de hatalı sonuç üretecek.
  // bunu engellemek için global tarihi firebase'den öğreneceğiz.

  return firebaseDatabase
    .ref("/.info/serverTimeOffset")
    .once("value")
    .then(
      (data) => {
        const day = new Date(data.val() + Date.now());
        return new Date(day.setHours(0, 0, 0, 0));
      },
      (err) => {
        alert("Server'daki tarih bilgisine erişilemiyor...");
        return err;
      }
    );
};

const pullSettings = async () => {
  return firebaseDatabase
    .ref("settings")
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) return snapshot.val();
      else return {};
    });
};

const updateSettings = async (updates) => {
  return firebaseDatabase.ref().update(updates);
};

export {
  pullQuestions,
  pullQuestionByKey,
  pullAnswers,
  pullAnswerByKey,
  pullSampleQuestionByCategory,
  pullCategories,
  updateQuestionAndAnswer,
  getUser,
  listenAuthenticatedUserChanges,
  logout,
  saveQuestionOrders,
  insertNewQuestionAndAnswer,
  updateCategory,
  pullFilteredQuestions,
  getServerDate,
  getNoFromSequence,
  getSetSequence,
  pullSettings,
  updateSettings,
  
};

// **************** Export edilmeyen private fonksiyonları aşağıya yazıyorum **********************


async function writeImageToStorage({ file, dbKey, fileStoragePath } = {}) {
  return new Promise(function (resolve, reject) {
    const storageRef = firebaseStorage
      .ref()
      .child(fileStoragePath + "/" + dbKey);
    const uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      function error(err) {
        console.log("error", err);
        alert("Resim karşı tarafa aktarılırken bir hata oldu...");
        reject();
      },
      function complete() {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      }
    );
  });
}

async function getNoFromSequence() {
  // const increment = firebase.firestore.FieldValue.increment(1);

  // await firebase.database()
  //   .ref('questionSequence')
  //   .set(firebase.database.ServerValue.increment(1));

  // // await firebaseDatabase
  // //   .ref("questionSequence").update(increment);

  //    let res = await firebaseDatabase
  //   .ref("questionSequence")
  //   .once("value")
  //   .then(async (snapshot) => {
  //     if (snapshot.val()) {
  //       console.log("snapshot.val() : ", snapshot.val());

  //       return snapshot.val();
  //     }
  //   });

  let oldSequence = await firebaseDatabase
    .ref("questionSequence")
    .once("value")
    .then(async (snapshot) => {
      if (snapshot.val()) {
        return snapshot.val();
      }
    });

  const nextSequence = oldSequence + 1;

  let updates = {};
  updates["/questionSequence"] = nextSequence;

  let res = await firebaseDatabase
    .ref()
    .update(updates)
    .then(() => {
      return nextSequence;
    });

  return res;
}

async function getSetSequence(incrementAmount) {
  // bu fonksiyon, sequence'in şu anki değerini return eder, fakat   return etmeden hemen önce sequence'e incrementAmount ekleyip update eder.
  // neden buna ihtiyaç var? sequence'i 1 artıran incrementSequence diye bi fonksiyon yazıp bunu her lazım olduğunda çalıştırsak olmaz mı??
  // batch update'lerde olmuyor: firebase upadte işlemlerini bi pipeline'a atıp hepsini aynı anda yapıyor ki çok network yemesin. for dongüsü içinde 100 kere incrementSequence
  // çalıştırsan sequence'i sadece 1 kere update ediyor. (Zaten firebase'in built-in auto increment özelliği yok. distributed database olduğu için relational'lardan farklı çalışıyor.)

  let oldSequence = await firebaseDatabase
    .ref("questionSequence")
    .once("value")
    .then(async (snapshot) => {
      if (snapshot.val()) {
        return snapshot.val();
      }
    });

  const nextSequence = oldSequence + incrementAmount;

  let updates = {};
  updates["/questionSequence"] = nextSequence;

  await firebaseDatabase
    .ref()
    .update(updates)
    .then(() => {
      return nextSequence;
    });

  return oldSequence; // dikkat. Özellikle old sequence'i return ettik. Hata yok.
}

function addCategoryIfNotExists(category) {
  if (category && category.trim().length > 0) {
    firebaseDatabase
      .ref("categories")
      .child(category.trim())
      .once("value")
      .then((snapshot) => {
        if (!snapshot.val()) {
          // böyle bir kategori yoksa ekleyelim:
          let updates = {};
          updates["/categories/" + category.trim() + "/questionSelectionMethod"] = 1;
          firebaseDatabase.ref().update(updates);
        }
      });
  }
}

async function addRandomQuestionsIfNeeded(questions, questionDate) {
  //daha önceden belirlenmiş soruları çektik. Şimdi de random olarak seçilmesi gereken soru var mı diye kontrol edelim:
  // random işaretlenmiş kategorilerin her birinden en az 1 soru olması gerekiyor.
  // questions'ın katgorilerini bi listeye atalım. Tüm kategorileri de 1 listeye atalım. Aradaki fark listesi için random soru çekelim.

  // bu random soru ekleme işlemi, sadece bugün ve sonrası için geçerli olacak. Geçmişe random soru insert edilmeyecek.

  const today = await getServerDate();

  if (questionDate >= today) {
    const currentQuestionsCategories = [
      ...new Set(questions.map((q) => q.category)),
    ];
    const allCategories = await pullCategories();

    const allRandomSelectionCategories = allCategories
      .filter((c) => c.selectRandom === true)
      .map((c) => c.name);

    const missingRandomSelectionCategories = allRandomSelectionCategories.filter(
      (x) => !currentQuestionsCategories.includes(x)
    );

    // random soru eklememiz gereken ve henüz eklemediğimiz kategorileri bulduk: missingRandomSelectionCategories...
    // şimdi bu listedeki her eleman için bir adet random soru seçip o sorunun tarihini questionDate'e set edeceğiz.

    for (let i = 0; i < missingRandomSelectionCategories.length; i++) {
      const categoryName = missingRandomSelectionCategories[i];
      const randomQuestion = await findARandomQuestionFromTheCategory(
        categoryName,
        questionDate,
        today
      );

      if (randomQuestion) {
        await updatePublishDatesOfQuestionAndAnswer(
          randomQuestion.dbKey,
          questionDate,
          today
        );

        questions.push(randomQuestion);
      }
    }
  }
}

async function updatePublishDatesOfQuestionAndAnswer(
  dbKey,
  questionDate,
  serverTime
) {
  // question date'i değiştirir. Answer date'i de ertesi gün olacak şekilde değiştirir.
  let updates = {};
  updates["/questions/" + dbKey + "/publishDate"] = convertDateToString(
    questionDate
  );
  updates["/questions/" + dbKey + "/randomSelectionTime"] = serverTime;
  updates["/answers/" + dbKey + "/publishDate"] = convertDateToString(
    addDaysToDate(questionDate, 1)
  );

  return await firebaseDatabase.ref().update(updates);
}

async function pullEveryDayQuestions() {
  let questions = [];

  await firebaseDatabase
    .ref("questions")
    .orderByChild("publishDate")
    .equalTo("01.01.2100")
    .once("value")
    .then(async (snapshot) => {
      if (snapshot.val()) {
        questions = Object.keys(snapshot.val()).map(function (key) {
          let questionObject = snapshot.val()[key];
          questionObject["dbKey"] = key; //  edit vs. yaparken lazım olacak.
          return questionObject;
          // return snapshot.val()[key];
        });
      }
    });

  return questions;
}

async function pullCategoryQuestions(category) {
  return firebaseDatabase
    .ref("questions")
    .orderByChild("category")
    .equalTo(category)
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        let categoryQuestions = [];
        const keys = Object.keys(snapshot.val());

        for (let i = 0; i < keys.length; i++) {
          const questionObject = snapshot.val()[keys[i]];
          questionObject["dbKey"] = keys[i];
          categoryQuestions.push(questionObject);
        }
        return categoryQuestions;
      } else return [];
    });
}

async function pullSpecificDayQuestions(publishDate) {
  return firebaseDatabase
    .ref("questions")
    .orderByChild("publishDate")
    .equalTo(convertDateToString(publishDate))
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        let specificDayQuestions = [];
        const keys = Object.keys(snapshot.val());

        for (let i = 0; i < keys.length; i++) {
          const questionObject = snapshot.val()[keys[i]];
          questionObject["dbKey"] = keys[i];
          specificDayQuestions.push(questionObject);
        }
        return specificDayQuestions;
      } else return [];
    });
}

async function pullAllQuestions() {
  return firebaseDatabase
    .ref("questions")
    .once("value")
    .then((snapshot) => {
      if (snapshot.val()) {
        let allQuestions = [];
        const keys = Object.keys(snapshot.val());

        for (let i = 0; i < keys.length; i++) {
          const questionObject = snapshot.val()[keys[i]];
          questionObject["dbKey"] = keys[i];
          allQuestions.push(questionObject);
        }
        return allQuestions;
      } else return [];
    });
}

function filtrateQuestions(questions, filter) {
  const {
    selectedCategory,
    publishDate,
    dateOption, // all, specificDay, empty
    searchText,
    searchInTitle,
    searchInSubtitle,
    searchInQuestionNumber,
  } = filter;
  const searchString = searchText.trim();

  const filteredQuestions = [];

  for (let i = 0; i < questions.length; i++) {
    const questionObject = questions[i];

    const categoryIsProper =
      selectedCategory === "TÜMÜ" ||
      selectedCategory === questionObject.category;

    const publishDateIsProper =
      dateOption === "all" ||
      (dateOption === "specificDay" &&
        convertDateToString(publishDate) === questionObject.publishDate) ||
      (dateOption === "empty" && !questionObject.publishDate);

    const textIsProper =
      (!searchInTitle && !searchInSubtitle && !searchInQuestionNumber) ||
      (searchInTitle && questionObject.title.search(searchString) > -1) ||
      (searchInSubtitle &&
        questionObject.subtitle1.search(searchString) > -1) ||
      (searchInQuestionNumber &&
        !isNaN(searchString) &&
        questionObject.no === parseInt(searchString));

    if (categoryIsProper && publishDateIsProper && textIsProper)
      filteredQuestions.push(questionObject);
  }

  return filteredQuestions;
}

async function findARandomQuestionFromTheCategory(
  categoryName,
  questionDate,
  today
) {
  //verilen kategoriye ait sorular içinden,
  //verilen questionDate'ten max x gün önce ve max x gün sonra sorulan sorular hariç
  //1 adet rastgele soru seçip sorunun yayın tarihini questionDate olarak set eder. ve soruyu return eder.

  //TODO: BURADA ŞÖYLE BİR PROBLEM OLABİLİR: Bir soru yarın için atandı. Sonra ben 1 yıl sonraya tıkladım. 1 yl sonra güvenli bölge içinde diye
  // tesadüf eseri o soruyu tekrar seçti, tarihini değiştirdi. Bu sefer yarınki data kayboldu. Buna da bir çözüm düşünülmeli.

  const restrictedDaySpan = 40; // 40 gün önceki soru artık unutulmaya başlamıştır herhalde... Tamamen kafadan uydurdum bu 40'ı.
  const todayDateString = convertDateToString(today);

  const allQuestionsOfThisCategory = await pullCategoryQuestions(categoryName);

  if (allQuestionsOfThisCategory.length > 0) {
    const safeZoneQuestions = [];

    for (let i = 0; i < allQuestionsOfThisCategory.length; i++) {
      const q = allQuestionsOfThisCategory[i];
      if (q.publishDate) {
        // publishDate'i olan yani daha önceden yayınlanmış sorulardan biriyse, bakalım güvenli bölgede mi?
        const diffTime = Math.abs(
          convertStringToDate(q.publishDate) - questionDate
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // bu koşul sağlanıyorsa, güvenli bölgedeyiz. Buradan soru seçilebilir.
        if (diffDays > restrictedDaySpan)
          if (todayDateString !== q.publishDate)
            // edit: uzak bir tarihe soru seçerken yanlışlıkla bugün yayınlanmakta olan bir soruyu seçmemeliyiz.(bazen today de safe-zone'da olabilir.)
            safeZoneQuestions.push(q);
      } else {
        // daha önce hiç yayınlanmamış bir soruya denk geldik!
        // hiç işlemci yormaya gerek yok bunu rastgele seçili soru olarak return et gitsin!
        // safeZoneQuestions.push(q);
        return q;
      }
    }

    if (safeZoneQuestions.length > 0) {
      const randomIndex = Math.floor(Math.random() * safeZoneQuestions.length);
      return safeZoneQuestions[randomIndex];
    } else {
      // güvenli bölgede hiç soru yok. O zaman eli boş dönmeyelim.
      //Yakın zamanda sorulmuş olmasını dikkate almadan tamamen rastgele bir soruyu seçelim.
      // Random bir kategori için yeterince soru varsa zaten bu else'e asla girmeyecek. Mutlaka güvenli bölgede soru kalacak.

      const randomIndex = Math.floor(
        Math.random() * allQuestionsOfThisCategory.length
      );
      return allQuestionsOfThisCategory[randomIndex];
    }
  } else {
    // seçilen kategoriden hiç soru yokmuş. (Normalde bu mümkün değil. Bi kategorinin tüm soruları db'den manuel silinirse olabilir ancak.)
    return null;
  }
}