export default {
    admin: {
        index: '/admin',
        archive: '/archive',
        sorting: '/sorting',
        login:'/login',
        settings: '/settings'
    },
    questions: '/questions',
    answers: '/answers'
}
