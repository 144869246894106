import React, { Component } from "react";
import mebLogo from "../assets/images/meb_logo.jpeg";
import "../css/Styles.css";
import Grid from "@material-ui/core/Grid";

class Footer extends Component {
  render() {
    const leftInfo = (
      <div className="mebBox">
        <img className="mebLogo" src={mebLogo} alt="meb_logo_missing" />

        <div className="mebText">
          <b>Milli Eğitim Bakanlığı</b> Uzaktan Eğitim Sürecinde öğrencilerimiz
          ve öğretmenlerimizin yararlanması için <b>GriCeviz</b> tarafından
          geliştirilmiştir.
        </div>
      </div>
    );

    const rightInfo = (
      <div className="copyRightBox">
        <br />
        <span className="descriptionText">
          Akıl ve Zeka Oyunlarına ilgi duyan herkes yararlanabilir. İçerikler
          ticari amaçla kullanılamaz, kaynak gösterilmeden alıntı yapılamaz ve
          başka bir yerde yayınlanamaz.
        </span>
        <span className="allRightsReservedText">
          Tüm hakları saklıdır. ©<span className="link">griceviz.com</span> 2021{" "}
        </span>
      </div>
    );

    return (
      // <div
      //   style={{
      //     display: "flex",
      //     flexWrap:"wrap",
      //     justifyContent: "space-between",
      //     marginTop: "3vh",
      //     marginBottom: "2vh",
      //     alignItems: "center",
      //     // fontSize: "calc(6px + 1vmin)",
      //   }}
      // >
      //   {leftInfo}
      //   {rightInfo}
      // </div>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginTop: "3vh", marginBottom: "2vh" }}
      >
        <Grid item md={6} lg={5} xl={4}>
          {leftInfo}
        </Grid>

        <Grid
          item
          md={1}
          lg={2}
          xl={4} // araya boşluk vermek için.
        />

        <Grid item md={5} lg={5} xl={4}>
          {rightInfo}
        </Grid>
      </Grid>
    );
  }
}

export default Footer;
