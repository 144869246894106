import React, { Component } from "react";
import {
  pullQuestions,
  pullAnswers,
  listenAuthenticatedUserChanges,
} from "./db/FirebaseMethods";
import { getServerDate } from "./db/FirebaseMethods";
import { convertStringToDate, convertDateToString } from "./HelperFunctions";

const AppContext = React.createContext();

class AppProvider extends Component {
  // Context state
  // state = {
  //   selectedDate: new Date(),
  //   questions: [],
  //   answers: [],
  //   user: {},
  //   userIsAnAdmin: false,
  // };

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      questions: [],
      isPullingQuestions: false,
      answers: [],
      isPullingAnswers: false,
      user: {},
      userIsAnAdmin: false,
    };
  }

  componentDidMount() {
    this.setDateToToday();
    listenAuthenticatedUserChanges((user) => {
      if (user) {
        this.setUser(user);
      } else {
        this.setState({
          user: {},
          userIsAnAdmin: false,
        });
        this.setDateToToday();
      }
    });
  }

  pullQuestionsFromDb = async (questionDate) => {
    if (!this.state.isPullingQuestions) {
      this.setState({ isPullingQuestions: true }, async () => {
        const questions = await pullQuestions(questionDate);
        this.setState({ questions, isPullingQuestions: false });
      });
    }
  };

  pullAnswersFromDb = async (answerDate) => {
    if (!this.state.isPullingAnswers) {
      this.setState({ isPullingAnswers: true }, async () => {
        const answers = await pullAnswers(answerDate);
        this.setState({ answers, isPullingAnswers: false });
      });
    }
  };

  setDateToToday = async () => {
    // burada today user'ın bilgisyarının tarihi değil, firebase db'nin tarihi olacak.
    const today = await getServerDate();
    this.changeDate(today);
  };

  changeDate = (newDate) => {
    this.setState({ selectedDate: newDate });
    this.pullQuestionsFromDb(newDate);
    this.pullAnswersFromDb(newDate);
  };

  setUser = (user) => {
    if (user) {
      const userIsAnAdmin = Object.keys(user).length > 0;
      this.setState({ user, userIsAnAdmin });
    } else {
      this.setState({ user: {}, userIsAnAdmin: false });
    }
  };

  reorderQuestions = (orders) => {
    //orders objesi. key'ler question id, value'lar soru sırası olacak şekilde key-value çiftleri içerir.

    let questions = this.state.questions;

    Object.keys(orders).map(function (key) {
      let q = questions.find((item) => item.dbKey === key);
      q.order = orders[key];
      return null;
    });

    questions.sort(function (first, second) {
      return first.order - second.order;
    });

    this.setState({ questions });
  };

  render() {
    const {
      selectedDate,
      questions,
      answers,
      user,
      userIsAnAdmin,
      isPullingAnswers,
      isPullingQuestions,
    } = this.state;
    const {
      changeDate,
      pullQuestionsFromDb,
      pullAnswersFromDb,
      setUser,
      reorderQuestions,
    } = this;

    return (
      <AppContext.Provider
        value={{
          selectedDate,
          questions,
          answers,
          user,
          userIsAnAdmin,
          isPullingAnswers,
          isPullingQuestions,
          changeDate,
          pullQuestionsFromDb,
          pullAnswersFromDb,
          setUser,
          reorderQuestions,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;

export { AppProvider };
