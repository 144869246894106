import React, { Component } from "react";

import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  NativeSelect,
  Button,
  Backdrop,
  CircularProgress,
  Slider,
  Switch,
} from "@material-ui/core";

import trLocale from "date-fns/locale/tr";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  pullQuestionByKey,
  pullAnswerByKey,
  pullCategories,
  updateQuestionAndAnswer,
  insertNewQuestionAndAnswer,
  pullSampleQuestionByCategory,

} from "../../db/FirebaseMethods";
import QuestionCard from "../QuestionCard";
import { Grid } from "@material-ui/core";
import "../../css/Styles.css";

import AppContext from "../../AppContext";

import {
  convertStringToDate,
  convertDateToString,
} from "../../HelperFunctions";

class EditQuestion extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    // const today = new Date().toLocaleDateString("tr-TR");
    // const tomorrow = new Date(
    //   new Date().setDate(new Date().getDate() + 1)
    // ).toLocaleDateString("tr-TR");
    this.state = {
      question: {}, //{ publishDate: today, difficulty: 0 },
      questionImageFile: null,
      answerImageFile: null,
      sampleImageFile: null,
      headerImageFile: null,
      answer: {}, //{ publishDate: tomorrow },
      categoryOption: "select",
      newCategoryName: "",
      selectedCategory: "",
      categories: [],
      isLoading: false,
      publishQuestionAlways: false,
    };
  }

  componentDidMount() {
    const dbKey = this.props.dbKey;

    if (dbKey) {
      // Yeni soru ekleme işleminde props'ta dbKey olmayacak. Soru editlerken dbKey var.
      this.pullQuestionByKey(dbKey);
      this.pullAnswerByKey(dbKey);
    }

    this.pullCategoriesFromDb();
  }

  handleClose = () => {
    this.props.onClose();
  };

  pullQuestionByKey = async (dbKey) => {
    const question = await pullQuestionByKey(dbKey);

    this.setState({ question, selectedCategory: question.category });
  };

  pullCategoriesFromDb = async () => {
    const categories = await pullCategories();
    this.setState({ categories });
  };

  pullAnswerByKey = async (dbKey) => {
    const answer = await pullAnswerByKey(dbKey);
    this.setState({ answer });
  };

  handleQuestionImageSelect = (e) => {
    const questionState = this.state.question;

    const imageFile = e.target.files[0];
    const url = URL.createObjectURL(imageFile);

    this.setState({
      question: { ...questionState, imageSource: url },
      questionImageFile: imageFile,
    });
  };

  handleAnswerImageSelect = (e) => {
    const answerState = this.state.answer;

    const imageFile = e.target.files[0];
    const url = URL.createObjectURL(imageFile);

    this.setState({
      answer: { ...answerState, imageSource: url },
      answerImageFile: imageFile,
    });
  };

  handleSampleImageSelect = (e) => {
    const questionState = this.state.question;

    const imageFile = e.target.files[0];
    const url = URL.createObjectURL(imageFile);

    this.setState({
      question: { ...questionState, sampleImageSource: url },
      sampleImageFile: imageFile,
    });
  };

  handleHeaderImageSelect = (e) => {
    const questionState = this.state.question;

    const imageFile = e.target.files[0];
    const url = URL.createObjectURL(imageFile);

    this.setState({
      question: { ...questionState, headerImageSource: url },
      headerImageFile: imageFile,
    });
  };

  handleCatgoryChange = async (event) => {
    // KAtegori değişince, o kategoriye ait sample bi soru indirelim, onun soru metni, örnek metni örnek görseli vs. ordan kopyalayalım ki her soru için tek tek uğraşılmasın.
    // bu işlemi sadece sonunda klişe ya da random yazan sorular için yapcaz.
    const categoryName = event.target.value;
    const thisIsEdit = this.props.dbKey;

    this.setState({isLoading:true});
    let q = { ...this.state.question };
    let a = { ...this.state.answer };
    q.category = categoryName;
    a.category = categoryName;

    let sampleQuestion = null;


    if (
      (categoryName.indexOf("Klişe") > -1 ||
      categoryName.indexOf("Random" > -1)) && !thisIsEdit
    ) {
      sampleQuestion = await pullSampleQuestionByCategory(categoryName);

      if(sampleQuestion){
        const q_key=Object.keys(sampleQuestion)[0];
        q=sampleQuestion[q_key];

        a = await pullAnswerByKey(q_key);

        delete q.no;
        delete q.order;
        delete q.publishDate;
        delete q.imageSource;
        delete q.randomSelectionTime;

        delete a.no;
        delete a.order;
        delete a.publishDate;
        delete a.imageSource;
       
        
      }


    }

    this.setState({
      isLoading:false,
      question: {...q},
      answer: { ...a},
      selectedCategory: categoryName,
    }, ()=>{
      console.log("state: ", this.state);
    });
  };

  renderInputs = () => {
    const questionState = this.state.question;

    const answerState = this.state.answer;

    const questionTitle = (
      <TextField
        variant="outlined"
        value={questionState.title || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, title: newVal },
            answer: { ...answerState, title: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
      />
    );
    const newCategoryField = (
      <TextField
        variant="outlined"
        value={this.state.newCategoryName || ""}
        onChange={(event) =>
          this.setState({
            question: { ...questionState, category: event.target.value.trim() },
            answer: { ...answerState, category: event.target.value.trim() },
            newCategoryName: event.target.value,
          })
        }
        size={"small"}
      />
    );
    const categoryList = (
      <NativeSelect
        name="category"
        value={this.state.selectedCategory}
        onChange={this.handleCatgoryChange}
      >
        <option value="" />
        {this.state.categories.map(function (item) {
          return (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </NativeSelect>
    );
    const categorySelectionDiv = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <RadioGroup
          row
          onChange={(event) => {
            const newVal = event.target.value;
            this.setState({ categoryOption: newVal });

            // önce kategori seçer, sonra vazgeçip yeni kategori ismi girer, sonra tekrar kategori seçmeye dönüp
            // az önce seçtiği kategoriyi değiştirmezse(!) hatalı kayıt olacak. Buna mahal vermeyelim:
            const ctgr =
              newVal === "select"
                ? this.state.selectedCategory
                : newVal === "add"
                ? this.state.newCategoryName
                : "";

            this.setState({
              question: {
                ...questionState,
                category: ctgr,
              },
              answer: {
                ...answerState,
                category: ctgr,
              },
            });
          }}
          value={this.state.categoryOption}
        >
          <FormControlLabel
            value="select"
            control={<Radio />}
            label="Kategori Seç"
          />
          <FormControlLabel
            value="add"
            control={<Radio />}
            label="Yeni Kategori Ekle"
          />
        </RadioGroup>

        {this.state.categoryOption === "select"
          ? categoryList
          : newCategoryField}
      </div>
    );

    const questionText_1 = (
      <TextField
        variant="outlined"
        value={questionState.subtitle1 || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, subtitle1: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    const sampleText = (
      <TextField
        variant="outlined"
        value={questionState.sampleText || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, sampleText: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    const answerText_1 = (
      <TextField
        variant="outlined"
        value={answerState.subtitle1 || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            answer: { ...answerState, subtitle1: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    const answerPreview = <QuestionCard item={answerState} />;
    const answerImageSelector = (
      <input type="file" onChange={this.handleAnswerImageSelect} />
    );

    const sampleImageSelector = (
      <input type="file" onChange={this.handleSampleImageSelect} />
    );

    const headerImageSelector = (
      <input type="file" onChange={this.handleHeaderImageSelect} />
    );

    const questionDatePicker = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {questionState.publishDate !== "01.01.2100" && (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
            <KeyboardDatePicker
              style={{ width: "150px", marginRight: "40px" }}
              id="date-picker-dialog"
              // label="Date picker dialog"
              format="dd.MM.yyyy"
              value={
                questionState.publishDate
                  ? convertStringToDate(questionState.publishDate)
                  : null
              }
              onChange={(date) => {
                this.setState({
                  question: {
                    ...questionState,
                    publishDate: date ? convertDateToString(date) : "",
                  },
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        )}
        <Switch
          checked={questionState.publishDate === "01.01.2100"}
          onChange={(event) => {
            if (event.target.checked) {
              this.setState({
                question: {
                  ...questionState,
                  publishDate: "01.01.2100", // tarih buysa soruyu/banner'ı hergün ekrana getirecek.
                },
              });
            } else {
              this.setState({
                question: {
                  ...questionState,
                  publishDate: null, // new Date().toLocaleDateString("tr-TR"),
                },
              });
            }
          }}
          name="checkedA"
          label="Hergün Yayınla"
        />
        Hergün Yayınla
      </div>
    );

    const answerDatePicker = (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
        <KeyboardDatePicker
          style={{ width: "150px" }}
          margin="normal"
          id="date-picker-dialog"
          // label="Date picker dialog"
          format="dd.MM.yyyy"
          value={
            answerState.publishDate
              ? convertStringToDate(answerState.publishDate)
              : null
          }
          onChange={(date) => {
            this.setState({
              answer: {
                ...answerState,
                publishDate: date ? convertDateToString(date) : "",
              },
            });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    );

    const firstPublishDatePicker = (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={trLocale}>
        <KeyboardDatePicker
          style={{ width: "150px" }}
          margin="normal"
          id="date-picker-dialog"
          // label="Date picker dialog"
          format="dd.MM.yyyy"
          value={
            questionState.firstPublishDate
              ? convertStringToDate(questionState.firstPublishDate)
              : null
          }
          onChange={(date) => {
            this.setState({
              question: {
                ...questionState,
                firstPublishDate: date ? convertDateToString(date) : "",
              },
            });
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    );

    const questionImageSelector = (
      <div>
        <input type="file" onChange={this.handleQuestionImageSelect} />
        <Switch
          checked={questionState.imageCoversTheQuestion === true}
          onChange={(event) => {
            this.setState({
              question: {
                ...questionState,
                imageCoversTheQuestion: event.target.checked,
              },
            });
          }}
        />
        Tüm Kutuyu Kapla
        <TextField
          style={{ marginLeft: "40px" }}
          placeholder="Yönlendirme adresi."
          variant="outlined"
          value={questionState.imageRedirectionUrl || ""}
          onChange={(event) => {
            const newVal = event.target.value;
            this.setState({
              question: { ...questionState, imageRedirectionUrl: newVal },
            });
          }}
          size={"small"}
        />
      </div>
    );

    const questionPreview = <QuestionCard item={questionState} />;
    const difficultyPicker = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="difficultyLevel">{questionState.difficulty || 0}</div>
        <Slider
          value={Number(questionState.difficulty)}
          defaultValue={0}
          aria-labelledby="discrete-slider-always"
          step={1}
          min={1}
          max={5}
          style={{ width: 300 }}
          onChange={(event, newValue) => {
            this.setState({
              question: { ...questionState, difficulty: newValue },
            });
          }}
        />
      </div>
    );

    const firstPublishPlace = (
      <TextField
        variant="outlined"
        value={questionState.firstPublishPlace || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, firstPublishPlace: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    const note1 = (
      <TextField
        variant="outlined"
        value={questionState.note1}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, note1: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    const note2 = (
      <TextField
        variant="outlined"
        value={questionState.note2 || ""}
        onChange={(event) => {
          const newVal = event.target.value;
          this.setState({
            question: { ...questionState, note2: newVal },
          });
        }}
        fullWidth={true}
        size={"small"}
        multiline
      />
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
              <DataRow label={"Kategori"} inputs={categorySelectionDiv} />
                <DataRow label={"Başlık"} inputs={questionTitle} />
                <DataRow label={"Soru Metni"} inputs={questionText_1} />

                <DataRow
                  label={"Başlık Görseli"}
                  inputs={headerImageSelector}
                />
                <DataRow
                  style={{backgroundColor:"#F8BBD0"}}
                  label={"Soru Görseli"}
                  inputs={questionImageSelector}
                />
                <DataRow label={"Örnek Metni"} inputs={sampleText} />

                <DataRow label={"Örnek Görseli"} inputs={sampleImageSelector} />
                <DataRow
                  label={"Soru Yayın Tarihi"}
                  inputs={questionDatePicker}
                />
                <DataRow label={"Cevap Metni"} inputs={answerText_1} />

                <DataRow style={{backgroundColor:"#F8BBD0"}} label={"Cevap Görseli"} inputs={answerImageSelector} />
                <DataRow
                  label={"Cevap Yayın Tarihi"}
                  inputs={answerDatePicker}
                />
                <DataRow label={"Zorluk Derecesi"} inputs={difficultyPicker} />
                <DataRow label={"İk Yayın Yeri"} inputs={firstPublishPlace} />
                <DataRow
                  label={"İlk Yayın Tarihi"}
                  inputs={firstPublishDatePicker}
                />
                <DataRow label={"Not-1"} inputs={note1} />
                <DataRow label={"Not-2"} inputs={note2} />
              </TableBody>
            </Table>
          </TableContainer>
          {this.renderSaveCancelButtons()}
        </Grid>

        <Grid item sm={4}>
          {questionPreview}
          {answerPreview}
        </Grid>
      </Grid>
    );
  };

  handleSave = async () => {
    this.setState({ isLoading: true });
    if (this.props.dbKey) {
      await updateQuestionAndAnswer(
        this.props.dbKey,
        this.state.question,
        this.state.questionImageFile,
        this.state.answer,
        this.state.answerImageFile,
        this.state.headerImageFile,
        this.state.sampleImageFile
      );
    } else {
      await insertNewQuestionAndAnswer(
        this.state.question,
        this.state.questionImageFile,
        this.state.answer,
        this.state.answerImageFile,
        this.state.headerImageFile,
        this.state.sampleImageFile
      );
    }

    if (
      this.state.question.publishDate ===
      convertDateToString(this.context.selectedDate)
    )
      await this.context.pullQuestionsFromDb(this.context.selectedDate);

    if (
      this.state.answer.publishDate ===
      convertDateToString(this.context.selectedDate)
    )
      await this.context.pullAnswersFromDb(this.context.selectedDate);

    this.handleClose();
  };

  renderSaveCancelButtons = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "40%",
          marginLeft: "40%",
          marginTop: "100px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleClose}
        >
          İPTAL
        </Button>
        <Button variant="contained" color="primary" onClick={this.handleSave}>
          KAYDET
        </Button>
      </div>
    );
  };

  renderLoadingIndicator = () => {
    return (
      <Backdrop
        style={{ zIndex: 999, color: "#fff" }}
        open={this.state.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  render() {
    return (
      <Dialog
        disableBackdropClick={true}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth={"xl"}
        fullWidth={true}

        // fullScreen={true}
      >
        <div style={{ padding: "30px" }}>
          {this.renderInputs()}
          {this.renderLoadingIndicator()}
        </div>
      </Dialog>
    );
  }
}

export default EditQuestion;

function DataRow(props) {
  return (
    <TableRow style={{...props.style}}>
      <TableCell component="th" scope="row" style={{ padding: "5px" }}>
        {props.label}
      </TableCell>
      <TableCell style={{ padding: "5px" }}>{props.inputs} </TableCell>
    </TableRow>
  );
}
